<template>
  <div id="app">
    <ClientForm />
  </div>
</template>

<script>
import ClientForm from './components/ClientForm.vue';

export default {
  name: 'App',
  components: {
    ClientForm,
  }
};
</script>

<style>
@import "@/styles/main.css";
</style>